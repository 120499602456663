import React from 'react';
import Cookies from 'js-cookie';
import { RouteComponentProps } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';

import { db_Order } from '../../../../share/db';
import api from '../../../../share/api';
import CustomerSettings from '../../util/customerSettings';

import { OrderList } from '../../components/OrderList';
import LoadingAnimation from '../../components/LoadingAnimation/LoadinAnimation';
import ButtonPrimary from '../../components/ButtonPrimary';
import FilterForm from './FilterForm';

interface DashboardProps extends RouteComponentProps {}

interface DashboardState {
  orders: db_Order[];
  orders_display: db_Order[];
  isLoading: boolean;
  filterValue: string;
  filterState: string;
}

class Dashboard extends React.Component<DashboardProps, DashboardState, {}> {
  constructor(props: DashboardProps) {
    super(props);
    this.state = {
      orders: [],
      orders_display: [],
      isLoading: false,
      filterValue: '',
      filterState: 'all',
    };
  }

  async componentDidMount() {
    if (Cookies.get('authHeaderValue')) {
      const customerSettings: CustomerSettings = new CustomerSettings();
      this.setState({ isLoading: true });
      const getAllResponse: AxiosResponse<api.getAllOrderResult> = await axios({
        method: 'get',
        url: customerSettings.backend_api_url + '/v1/get-all-order',
        headers: { Authorization: Cookies.get('authHeaderValue') },
      });
      if (getAllResponse.data.Order.length > 0) {
        getAllResponse.data.Order.sort((a: db_Order, b: db_Order): number => {
          let d_a = new Date(a.date || 0);
          let d_b = new Date(b.date || 0);
          return d_b.getTime() - d_a.getTime();
        });
        this.setState({ orders: getAllResponse.data.Order });
        this.setState({ orders_display: getAllResponse.data.Order });
      }
      this.setState({ isLoading: false });
    } else {
      this.props.history.push('/login');
    }
  }

  clickToNewOrder = () => {
    this.props.history.push('/neworder');
  };

  clickOnOrder = (order: db_Order) => {
    return (event: React.MouseEvent<HTMLTableRowElement>) => {
      this.props.history.push('/order?orderId=' + order.id);
    };
  };

  filterStringOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ filterValue: event.currentTarget.value });
    setTimeout(this.applyFilter, 100);
  };

  filterStateOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ filterState: event.currentTarget.value });
    setTimeout(this.applyFilter, 100);
  };

  applyFilter = () => {
    const filterString: string = this.state.filterValue;
    const filterState: string = this.state.filterState;

    const filteredOrders: db_Order[] = [];

    for (let order of this.state.orders) {
      let stringFilterResult: boolean = false;
      if (filterString.length > 0) {
        if (
          order.paymentReference?.includes(filterString) ||
          order.clientName?.toLowerCase().includes(filterString.toLowerCase())
        ) {
          stringFilterResult = true;
        }
      } else {
        stringFilterResult = true;
      }

      let stateFilterResult: boolean = false;
      if (filterState === 'all') {
        stateFilterResult = true;
      } else {
        if (order.status === filterState) {
          stateFilterResult = true;
        }
      }

      if (stringFilterResult && stateFilterResult) {
        let copyOfOrder: db_Order = {};
        Object.assign(copyOfOrder, order);
        filteredOrders.push(copyOfOrder);
      }
    }

    this.setState({ orders_display: filteredOrders });
  };

  render() {
    return (
      <>
        <h1 className="title is-1">Order Dashboard</h1>
        <ButtonPrimary
          text="Create new order"
          active={true}
          onClick={this.clickToNewOrder}
        />
        <h2 className="title is-2">Order List</h2>
        <FilterForm
          filterString={this.state.filterValue}
          filterState={this.state.filterState}
          onStringChange={this.filterStringOnChange}
          onStateChange={this.filterStateOnChange}
          active={!this.state.isLoading}
        />
        <OrderList
          orders={this.state.orders_display}
          onOrderClick={this.clickOnOrder}
        />
        <LoadingAnimation isLoading={this.state.isLoading} />
      </>
    );
  }
}

export default Dashboard;
