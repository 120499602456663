import React, { ReactElement } from 'react';

export interface LoginFormProps {
  usValue: string;
  pwValue: string;
  usOnChange: any;
  pwOnChange: any;
  handleSubmit: any;
}

const LoginForm = (props: LoginFormProps): ReactElement => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div className="field">
        <label className="label">User</label>
        <input
          className="input"
          type="text"
          value={props.usValue}
          onChange={props.usOnChange}
        ></input>
      </div>
      <div className="field">
        <label className="label">Password</label>
        <input
          className="input"
          type="password"
          value={props.pwValue}
          onChange={props.pwOnChange}
        ></input>
      </div>
      <div className="field">
        <div className="control">
          <input
            className="button is-link"
            type="submit"
            value="Submit"
          ></input>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
