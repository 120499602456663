import React from 'react';

export interface ErrorMessageProps {
  message: string;
}

const ErrorMessage = (props: ErrorMessageProps) => {
  return (
    <>
      <p>
        {props.message.length > 1 ? (
          <div className="notification is-danger">{props.message}</div>
        ) : null}
      </p>
    </>
  );
};

export default ErrorMessage;
