import customerSettingsJson from '../customer_settings.json';

export interface DestinationLang {
  id: string;
  name: string;
  base_url: string;
}
export default class CustomerSettings {
  destination_langs?: DestinationLang[];
  backend_api_url: string;
  stripe_product_id: string;

  constructor() {
    this.destination_langs = customerSettingsJson.destination_langs;
    this.backend_api_url = customerSettingsJson.backend_api_url;
    this.stripe_product_id = customerSettingsJson.stripe_product_id;
  }

  getDestinationLangWithId = (id: string): DestinationLang | undefined => {
    if (this.destination_langs) {
      for (let dl of this.destination_langs) {
        if (dl.id === id) {
          return dl;
        }
      }
    }
    return undefined;
  };
}
