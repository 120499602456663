import React from 'react';
import axios, { AxiosResponse } from 'axios';
import { SHA3 } from 'sha3';
import Cookies from 'js-cookie';
import { RouteComponentProps } from 'react-router-dom';

import api from '../../../../share/api';

import LoginForm from '../../components/LoginForm';
import ErrorMessage from '../../components/ErrorMessage';
import LoadingAnimation from '../../components/LoadingAnimation/LoadinAnimation';
import CustomerSettings from '../../util/customerSettings';

interface LoginState {
  us: string;
  pw: string;
  errorMessage: string;
  isLoading: boolean;
}

interface LoginProps extends RouteComponentProps {}

class Login extends React.Component<LoginProps, LoginState, {}> {
  constructor(props: LoginProps) {
    super(props);

    this.state = {
      us: '',
      pw: '',
      errorMessage: '',
      isLoading: false,
    };
  }

  usHandleChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ us: event.currentTarget.value });
  };

  pwHandleChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ pw: event.currentTarget.value });
  };

  handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!this.state.isLoading) {
      this.setState({ isLoading: true });

      const username: string = this.state.us.slice(0);
      const password: string = this.state.pw.slice(0);

      const hasher = new SHA3(224);
      hasher.update(password);
      const pwHash: string = hasher.digest('hex');

      const authHeaderValue = 'Basic ' + btoa(username + ':' + pwHash);

      const customerSettings: CustomerSettings = new CustomerSettings();
      const authUserResponse: AxiosResponse<api.authUserResult> = await axios({
        method: 'get',
        url: customerSettings.backend_api_url + '/v1/auth-user',
        headers: { Authorization: authHeaderValue },
      });

      this.setState({ isLoading: false });

      if (!authUserResponse.data.Authorized) {
        this.setState({ errorMessage: 'Wrong username or password!' });
        this.setState({ us: '', pw: '' });
      } else {
        this.setState({ errorMessage: 'Ok' });
        Cookies.set('authHeaderValue', authHeaderValue);
        this.props.history.push('/dashboard');
      }
    }
  };
  render() {
    return (
      <>
        <h1 className="title is-1">Login</h1>
        <LoadingAnimation isLoading={this.state.isLoading} />
        <ErrorMessage message={this.state.errorMessage} />
        <LoginForm
          usValue={this.state.us}
          usOnChange={this.usHandleChange}
          pwValue={this.state.pw}
          pwOnChange={this.pwHandleChange}
          handleSubmit={this.handleFormSubmit}
        />
      </>
    );
  }
}

export default Login;
