import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Dashboard from '../Dashboard';
import Login from '../Login';
import LoginChecker from '../LoginChecker';
import NewOrder from '../NewOrder';
import Order from '../Order';

const App: React.FC = () => {
  console.info('Nanopay - v0.1.1');
  return (
    <Router>
      <Route component={LoginChecker} path="/" exact />
      <Route component={Login} path="/login" />
      <Route component={Order} path="/order" />
      <Route component={Dashboard} path="/dashboard" />
      <Route component={NewOrder} path="/neworder" />
    </Router>
  );
};

export default App;
