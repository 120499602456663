import React from 'react';

interface FilterFromProps {
  onStringChange: any;
  onStateChange: any;
  filterString: string;
  filterState: string;
  active: boolean;
}

const FilterFrom = ({
  onStateChange,
  onStringChange,
  filterState,
  filterString,
  active,
}: FilterFromProps) => {
  return (
    <div className="field-group">
      <div className="field is-inline-block-desktop">
        <label className="label">Filter</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder="Ref or Name"
            value={filterString}
            onChange={onStringChange}
            disabled={!active}
          />
        </div>
      </div>

      <div className="field is-inline-block-desktop">
        <label className="label">State</label>
        <div className="control">
          <div className="select">
            <select
              value={filterState}
              onChange={onStateChange}
              disabled={!active}
            >
              <option value="all">all</option>
              <option value="open">open</option>
              <option value="paid">paid</option>
              <option value="shipped">shipped</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterFrom;
