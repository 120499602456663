import React from 'react';

interface LoadingAnimationProps {
  isLoading: boolean;
}

const LoadingAnimation = (props: LoadingAnimationProps) => {
  //return <>{props.isLoading ? <div className="loadingCircle"></div> : null}</>;
  return (
    <>
      {props.isLoading ? (
        <progress className="progress is-small is-primary" max="100"></progress>
      ) : null}
    </>
  );
};

export default LoadingAnimation;
