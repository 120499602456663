import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { db_Order } from '../../../../share/db';
import Cookies from 'js-cookie';
import CustomerSettings from '../../util/customerSettings';
import axios, { AxiosResponse } from 'axios';
import api from '../../../../share/api';
import LoadingAnimation from '../../components/LoadingAnimation/LoadinAnimation';
import TextInputField from '../../components/TextInputField';
import ReadOnlyInputField from '../../components/ReadOnlyInputField';
import ButtonPrimary from '../../components/ButtonPrimary';
import HomeButton from '../../components/HoneButton';

const customerSettings: CustomerSettings = new CustomerSettings();

interface OrderProps extends RouteComponentProps {}
interface OrderState {
  Order: db_Order;
  isLoading: boolean;
  canBeSubmitted: boolean;
  isPaidTempCheckbox: boolean;
  isShippedTempCheckbox: boolean;
  changeStateOnSaveTo: string;
}

const dateToLocalString = (date: Date | undefined): string => {
  if (date) {
    return new Date(date).toLocaleString();
  }
  return '';
};

class Order extends React.Component<OrderProps, OrderState, {}> {
  constructor(props: OrderProps) {
    super(props);
    this.state = {
      Order: {
        id: '',
        clientName: '',
        amount: 0,
        comment: '',
        shippingLink: '',
      },
      isLoading: false,
      canBeSubmitted: false,
      isPaidTempCheckbox: false,
      isShippedTempCheckbox: false,
      changeStateOnSaveTo: '',
    };
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    if (Cookies.get('authHeaderValue')) {
      const url = new URL(window.location.href);
      if (url.searchParams.has('orderId')) {
        const orderId = url.searchParams.get('orderId');

        const getOrderResponse: AxiosResponse<api.getOrderResult> = await axios(
          {
            method: 'get',
            url:
              customerSettings.backend_api_url +
              '/v1/get-order?orderId=' +
              orderId,
          },
        );

        const respondedOrder: db_Order = getOrderResponse.data.Order;
        respondedOrder.shippingLink = '';

        respondedOrder.checkoutSessionIds = undefined;

        this.setState({ changeStateOnSaveTo: respondedOrder.status ?? '' });
        this.setState({ Order: respondedOrder });
      } else {
        console.error('no order id');
      }
    } else {
      this.props.history.push('/login');
    }
    this.checkIfCanBeSubmitted();
    this.setState({ isLoading: false });
  };

  checkIfCanBeSubmitted = () => {
    setTimeout(() => {
      if (
        this.state.Order.clientName &&
        this.state.Order.clientName.length > 0
      ) {
        this.setState({ canBeSubmitted: true });
      } else {
        this.setState({ canBeSubmitted: false });
      }
    }, 10);
  };

  onClientName = (event: React.FormEvent<HTMLInputElement>) => {
    const newOrder: db_Order = this.cloneOrder(this.state.Order);
    newOrder.clientName = event.currentTarget.value;
    this.setState({ Order: newOrder });
    this.checkIfCanBeSubmitted();
  };
  onAmount = (event: React.FormEvent<HTMLInputElement>) => {};
  onComment = (event: React.FormEvent<HTMLInputElement>) => {
    const newOrder: db_Order = this.cloneOrder(this.state.Order);
    newOrder.comment = event.currentTarget.value;
    this.setState({ Order: newOrder });
  };
  onShippingLink = (event: React.FormEvent<HTMLInputElement>) => {
    const newOrder: db_Order = this.cloneOrder(this.state.Order);
    newOrder.shippingLink = event.currentTarget.value;
    this.setState({ Order: newOrder });
  };

  cloneOrder = (object: db_Order): db_Order => {
    const newObj: db_Order = {};
    Object.assign(newObj, object);
    return newObj;
  };

  handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.name === 'paid') {
      this.setState({ isPaidTempCheckbox: event.currentTarget.checked });
      const newOrder: db_Order = this.cloneOrder(this.state.Order);
      if (event.currentTarget.checked) {
        newOrder.status = 'paid';
      } else {
        newOrder.status = 'open';
      }
      this.setState({ Order: newOrder });
    }
    if (event.currentTarget.name === 'shipped') {
      this.setState({ isShippedTempCheckbox: event.currentTarget.checked });
      const newOrder: db_Order = this.cloneOrder(this.state.Order);
      if (event.currentTarget.checked) {
        newOrder.status = 'shipped';
      } else {
        newOrder.status = 'paid';
      }
      this.setState({ Order: newOrder });
    }
  };

  handleRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ changeStateOnSaveTo: event.currentTarget.value });
  };

  onSave = async (event: React.ChangeEvent<HTMLButtonElement>) => {
    this.setState({ isLoading: true });

    const newOrder: db_Order = this.cloneOrder(this.state.Order);
    newOrder.status = this.state.changeStateOnSaveTo;
    if (
      this.state.Order.status === 'open' &&
      this.state.changeStateOnSaveTo === 'paid'
    ) {
      newOrder.payDate = new Date();
    }
    if (
      this.state.Order.status === 'paid' &&
      this.state.changeStateOnSaveTo === 'shipped'
    ) {
      newOrder.shipDate = new Date();
    }
    if (
      this.state.Order.status === 'open' &&
      this.state.changeStateOnSaveTo === 'shipped'
    ) {
      newOrder.payDate = new Date();
      newOrder.shipDate = new Date();
    }

    const updateOrderResponse: AxiosResponse<api.getOrderResult> = await axios({
      method: 'post',
      url: customerSettings.backend_api_url + '/v1/update-order',
      headers: {
        Authorization: Cookies.get('authHeaderValue'),
      },
      data: newOrder,
    });

    this.setState({ Order: updateOrderResponse.data.Order });
    this.setState({ isLoading: false });
  };

  render = () => {
    return (
      <>
        <HomeButton history={this.props.history} />
        <h1 className="title is-1">Edit Order</h1>
        <LoadingAnimation isLoading={this.state.isLoading} />
        <ReadOnlyInputField
          name="Payment Reference"
          value={this.state.Order.paymentReference}
        />
        <ReadOnlyInputField
          name="Client Pay Link"
          value={this.state.Order.clientLink}
        />

        <TextInputField
          name="Client Name"
          placeholder="Max Mustermann"
          onChange={this.onClientName}
          value={this.state.Order.clientName}
          active={!this.state.isLoading}
        />
        <ReadOnlyInputField
          name="Amount in €"
          value={this.state.Order.amount?.toFixed(2) + ' €'}
        />
        <TextInputField
          name="Comment (internal)"
          placeholder="Vine order xy"
          onChange={this.onComment}
          value={this.state.Order.comment}
          active={!this.state.isLoading}
        />

        <ReadOnlyInputField
          name="Order Date"
          value={dateToLocalString(this.state.Order.date)}
        />

        <ReadOnlyInputField
          name="Pay Date"
          value={dateToLocalString(this.state.Order.payDate)}
        />

        <ReadOnlyInputField
          name="Ship Date"
          value={dateToLocalString(this.state.Order.shipDate)}
        />

        <TextInputField
          name="Shipping Link"
          placeholder="https://www.dhl.de/....."
          onChange={this.onShippingLink}
          value={this.state.Order.shippingLink}
          active={!this.state.isLoading}
        />

        <div className="control">
          {this.state.Order.status === 'open' ? (
            <label className="radio">
              <input
                onChange={this.handleRadio}
                checked={this.state.changeStateOnSaveTo === 'open'}
                type="radio"
                name="open"
                value={this.state.Order.status}
                disabled={this.state.isLoading}
              />
              Open
            </label>
          ) : null}
          {this.state.Order.status === 'open' ||
          this.state.Order.status === 'paid' ? (
            <label className="radio">
              <input
                onChange={this.handleRadio}
                checked={this.state.changeStateOnSaveTo === 'paid'}
                type="radio"
                name="none"
                value="paid"
                disabled={this.state.isLoading}
              />
              Paid
            </label>
          ) : null}
          {this.state.Order.status === 'open' ||
          this.state.Order.status === 'paid' ||
          this.state.Order.status === 'shipped' ? (
            <label className="radio">
              <input
                onChange={this.handleRadio}
                checked={this.state.changeStateOnSaveTo === 'shipped'}
                type="radio"
                name="none"
                value="shipped"
                disabled={this.state.isLoading}
              />
              Shipped
            </label>
          ) : null}
        </div>
        {this.state.Order.status === 'open' &&
        this.state.changeStateOnSaveTo !== 'open' ? (
          <div className="notification is-warning is-light">
            By changing to this status the order can no longer be paid over
            credit card by the customer.
          </div>
        ) : null}
        <br></br>
        <ButtonPrimary
          text="Save"
          active={this.state.canBeSubmitted && !this.state.isLoading}
          onClick={this.onSave}
        />
        <LoadingAnimation isLoading={this.state.isLoading} />
      </>
    );
  };
}

export default Order;
