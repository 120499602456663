import React from 'react';
import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { RouteComponentProps } from 'react-router-dom';

import ButtonPrimary from '../../components/ButtonPrimary';
import TextInputField from '../../components/TextInputField';
import NumberInputField from '../../components/NumberInputField';
import LoadingAnimation from '../../components/LoadingAnimation/LoadinAnimation';
import HomeButton from '../../components/HoneButton';
import { db_Order } from '../../../../share/db';

import CustomerSettings from '../../util/customerSettings';
import api from '../../../../share/api';

const customerSetting: CustomerSettings = new CustomerSettings();

interface NewOrderProps extends RouteComponentProps {}

interface NewOrderState {
  Order: db_Order;
  newOrderCreated: boolean;
  canBeSubmitted: boolean;
  isLoading: boolean;
  refToClientLinkInput: React.RefObject<HTMLInputElement>;
}

class NewOrder extends React.Component<NewOrderProps, NewOrderState, {}> {
  constructor(props: NewOrderProps) {
    super(props);
    this.state = {
      Order: {
        clientName: '',
        amount: 0,
        comment: '',
        status: 'open',
        clientLink: '',
        paymentReference: '',
        language: customerSetting.destination_langs?.[0].id,
        stripeRedirectUrl: customerSetting.destination_langs?.[0].base_url,
        stripeProductId: customerSetting.stripe_product_id,
      },
      newOrderCreated: false,
      canBeSubmitted: false,
      isLoading: false,
      refToClientLinkInput: React.createRef(),
    };
  }

  componentDidMount() {
    if (Cookies.get('authHeaderValue')) {
    } else {
      this.props.history.push('/login');
    }
  }

  checkIfCanBeSubmitted = () => {
    setTimeout(() => {
      if (
        this.state.Order.amount &&
        this.state.Order.amount > 0 &&
        this.state.Order.clientName &&
        this.state.Order.clientName.length > 0
      ) {
        this.setState({ canBeSubmitted: true });
      } else {
        this.setState({ canBeSubmitted: false });
      }
    }, 10);
  };

  onClientName = (event: React.FormEvent<HTMLInputElement>) => {
    const newOrder: db_Order = this.cloneOrder(this.state.Order);
    newOrder.clientName = event.currentTarget.value;
    this.setState({ Order: newOrder });
    this.checkIfCanBeSubmitted();
  };
  onAmount = (event: React.FormEvent<HTMLInputElement>) => {
    const newOrder: db_Order = this.cloneOrder(this.state.Order);
    if (event.currentTarget.value.length > 0) {
      if (typeof parseInt(event.currentTarget.value) === 'number') {
        newOrder.amount = parseFloat(event.currentTarget.value);
        event.currentTarget.value = newOrder.amount + '';
      }
    } else {
      newOrder.amount = 0;
    }
    this.setState({ Order: newOrder });
    this.checkIfCanBeSubmitted();
  };
  onComment = (event: React.FormEvent<HTMLInputElement>) => {
    const newOrder: db_Order = this.cloneOrder(this.state.Order);
    newOrder.comment = event.currentTarget.value;
    this.setState({ Order: newOrder });
  };

  onLang = (event: React.FormEvent<HTMLSelectElement>) => {
    const newOrder: db_Order = this.cloneOrder(this.state.Order);
    newOrder.language = event.currentTarget.value;
    newOrder.stripeRedirectUrl = customerSetting.getDestinationLangWithId(
      newOrder.language,
    )?.base_url;
    this.setState({ Order: newOrder });
  };

  cloneOrder = (object: db_Order): db_Order => {
    const newObj: db_Order = {};
    Object.assign(newObj, object);
    return newObj;
  };

  onSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
    this.setState({ newOrderCreated: true, isLoading: true });

    const customerSettings: CustomerSettings = new CustomerSettings();

    // Add new Order in DB and get ID
    const putOrderResponse: AxiosResponse<api.getOrderResult> = await axios({
      method: 'post',
      url: customerSettings.backend_api_url + '/v1/put-order',
      headers: {
        Authorization: Cookies.get('authHeaderValue'),
      },
      data: this.state.Order,
    });

    const orderReturnedByDb: db_Order = putOrderResponse.data.Order;
    orderReturnedByDb.clientLink =
      customerSetting.getDestinationLangWithId(
        this.state.Order.language ?? 'de',
      )?.base_url +
      '?orderId=' +
      putOrderResponse.data.Order.id;
    orderReturnedByDb.paymentReference = orderReturnedByDb.id?.substring(
      orderReturnedByDb.id.length - 5,
    );

    this.setState({ Order: orderReturnedByDb });

    const clientLinkTextArea: HTMLInputElement =
      this.state.refToClientLinkInput.current ?? new HTMLInputElement();
    clientLinkTextArea.select();
    document.execCommand('copy');

    await axios({
      method: 'post',
      url: customerSettings.backend_api_url + '/v1/update-order',
      headers: {
        Authorization: Cookies.get('authHeaderValue'),
      },
      data: orderReturnedByDb,
    });

    this.setState({ isLoading: false });
  };

  render() {
    const langOption = customerSetting.destination_langs?.map((lang) => {
      return (
        <option key={lang.id} value={lang.id}>
          {lang.name}
        </option>
      );
    });

    return (
      <>
        <HomeButton history={this.props.history} />
        <h1 className="title is-1">Create new order</h1>

        <TextInputField
          name="Client Name"
          placeholder="Max Mustermann"
          onChange={this.onClientName}
          value={this.state.Order.clientName}
          active={!this.state.newOrderCreated}
        />
        <NumberInputField
          name="Amount in € (what the client has to pay)"
          placeholder="5.23"
          onChange={this.onAmount}
          value={this.state.Order.amount}
          active={!this.state.newOrderCreated}
        />
        <TextInputField
          name="Comment (internal)"
          placeholder="Vine order xy"
          onChange={this.onComment}
          value={this.state.Order.comment}
          active={!this.state.newOrderCreated}
        />

        <div className="field">
          <label className="label">Language</label>
          <div className="control">
            <div className="select">
              <select
                value={this.state.Order.language}
                onChange={this.onLang}
                disabled={this.state.newOrderCreated}
              >
                {langOption}
              </select>
            </div>
          </div>
        </div>

        <br />

        {this.state.Order.clientLink?.length ?? -1 > 0 ? (
          <div className="field">
            <label className="label">Client Link (copied to clipboard)</label>
            <div className="control">
              <input
                className="input"
                type="text"
                value={this.state.Order.clientLink}
                ref={this.state.refToClientLinkInput}
                readOnly
              />
            </div>
          </div>
        ) : null}

        {this.state.newOrderCreated ? null : (
          <ButtonPrimary
            text="Submit"
            active={this.state.canBeSubmitted}
            onClick={this.onSubmit}
          />
        )}
        <LoadingAnimation isLoading={this.state.isLoading} />
      </>
    );
  }
}

export default NewOrder;
