import React, { ReactElement } from 'react';

interface TextInputFieldProps {
  placeholder: string;
  name: string;
  onChange: any;
  value: string | undefined;
  active: boolean;
}

const TextInputField: React.FC<TextInputFieldProps> = (
  props: TextInputFieldProps,
): ReactElement => {
  return (
    <div className="field">
      <label className="label">{props.name}</label>
      <div className="control">
        <input
          className="input"
          type="text"
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          disabled={!props.active}
        />
      </div>
    </div>
  );
};

export default TextInputField;
