import React, { ReactElement } from 'react';

interface NumberInputFieldProps {
  placeholder: string;
  name: string;
  onChange: any;
  value: number | undefined;
  active: boolean;
}

const NumberInputField: React.FC<NumberInputFieldProps> = (
  props: NumberInputFieldProps,
): ReactElement => {
  return (
    <div className="field">
      <label className="label">{props.name}</label>
      <div className="control">
        <input
          className="input"
          type="number"
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          disabled={!props.active}
        />
      </div>
    </div>
  );
};

export default NumberInputField;
