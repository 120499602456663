import React, { ReactElement } from 'react';

interface ReadOnlyInputFieldProps {
  name: string;
  value: string | undefined;
}

const ReadOnlyInputField: React.FC<ReadOnlyInputFieldProps> = (
  props: ReadOnlyInputFieldProps,
): ReactElement => {
  return (
    <div className="field">
      <label className="label">{props.name}</label>
      <div className="control">
        <input
          className="input"
          type="text"
          value={props.value}
          readOnly
          disabled={true}
        />
      </div>
    </div>
  );
};

export default ReadOnlyInputField;
