import React from 'react';

interface HomeButtonProps {
  history: any;
}

const styleI = {
  display: 'inline-block',
  marginLeft: '8px',
};

const HomeButton = (props: HomeButtonProps) => {
  const localTest = (e: any) => {
    props.history.push('/dashboard');
  };
  return (
    <span onClick={localTest} className="icon clickable">
      <i style={styleI} className="fas fa-long-arrow-alt-left">
        {' '}
      </i>
      <i style={styleI} className="fas fa-home">
        {' '}
      </i>
    </span>
  );
};

export default HomeButton;
