import React from 'react';
import Cookies from 'js-cookie';
import { RouteComponentProps } from 'react-router-dom';

class LoginChecker extends React.Component<RouteComponentProps, {}> {
  async componentDidMount() {
    if (Cookies.get('authHeaderValue')) {
      this.props.history.push('/dashboard');
    } else {
      this.props.history.push('/login');
    }
  }
  render() {
    return (
      <>
        <h1>Check if logged in and redirect</h1>
      </>
    );
  }
}

export default LoginChecker;
