import React, { ReactElement } from 'react';

interface ButtonPrimaryProps {
  text: string;
  onClick: any;
  active: boolean;
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = (
  props: ButtonPrimaryProps,
): ReactElement => {
  return (
    <div className="control">
      <button
        onClick={props.onClick}
        disabled={!props.active}
        className="button is-primary"
      >
        {props.text}
      </button>
    </div>
  );
};

export default ButtonPrimary;
