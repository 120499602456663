import React from 'react';

import { db_Order } from '../../../../share/db';

interface PropsOrderList {
  orders: db_Order[];
  onOrderClick: any;
}

const dateToLocalString = (date: Date | undefined): string => {
  if (date) {
    return new Date(date).toLocaleString();
  }
  return '';
};

export const OrderList = (props: PropsOrderList) => {
  const orderList = props.orders.map((order: db_Order, _) => {
    return (
      <tr
        className={'clickable ' + order.status}
        key={order.id}
        onClick={props.onOrderClick(order)}
      >
        <td>{order.paymentReference}</td>
        <td>{order.clientName}</td>
        <td>{order.amount?.toFixed(2) + ' €'}</td>
        <td>{order.status}</td>
        <td>{dateToLocalString(order.date)}</td>
        <td>{dateToLocalString(order.payDate)}</td>
        <td>{dateToLocalString(order.shipDate)}</td>
        <td>{order.comment}</td>
      </tr>
    );
  });
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Payment Reference</th>
          <th>Client Name</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Order Date</th>
          <th>Pay Date</th>
          <th>Ship Date</th>
          <th>Comment</th>
        </tr>
      </thead>
      <tbody>
        {orderList.length > 0 ? (
          orderList
        ) : (
          <tr>
            <td>No Orders</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
